<template>
    <div class="integration-key-list">
        <pendo-card
            v-if="!usesMultiApp"
            title="Application Information"
            body-min-height="60px"
            class="integration-app-info"
            data-cy="integration-app-info-card">
            <div class="app-id">
                <div class="app-id--label">
                    Application ID
                </div>
                {{ activeAppId }}
            </div>
        </pendo-card>
        <pendo-table
            ref="integrationKeyTable"
            :loading="isFetchingKeys"
            :data="allIntegrationKeys"
            :columns="columns"
            :auto-height-offset="96"
            :default-sort="{
                prop: 'label',
                order: 'ascending'
            }"
            auto-height
            title="API Access"
            class="integration-key-table">
            <template #headerRight>
                <pendo-button
                    v-if="allIntegrationKeys.length"
                    :loading="isGeneratingKey"
                    type="link"
                    theme="app"
                    label="Generate Integration Key"
                    prefix-icon="plus"
                    @click="addIntegrationKey" />
            </template>
            <template #integrationkey="{ row }">
                <div class="integration-key-table--copy">
                    <span>
                        {{ row.integrationkey }}
                    </span>
                    <pendo-icon
                        v-pendo-clipboard="row.integrationkey"
                        :size="14"
                        type="clipboard" />
                </div>
            </template>
            <template #label="{ row }">
                <span>
                    {{ row.label }}
                </span>
            </template>
            <template #readwrite="{ row }">
                <pendo-icon
                    :size="16"
                    :stroke-width="3"
                    :type="getRowIconType(row)" />
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    :row="row"
                    :actions="[
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            tooltip: 'Delete'
                        }
                    ]"
                    @delete="showConfirmDeleteModal" />
            </template>
            <template #empty>
                <div class="integration-key-table--empty">
                    <pendo-empty-state
                        :icon="{ 'type': 'box', 'size': 36, 'stroke-width': 1.5 }"
                        description="You haven’t generated an integration key yet.">
                        <template #actions>
                            <pendo-button
                                class="integration-key-generate-button"
                                :loading="isGeneratingKey"
                                type="primary"
                                theme="app"
                                label="Generate Integration Key"
                                prefix-icon="plus"
                                @click="addIntegrationKey" />
                        </template>
                    </pendo-empty-state>
                </div>
            </template>
        </pendo-table>
        <pendo-table
            v-if="usesMultiApp"
            title="Application Information"
            class="application-info-table"
            height="500px"
            csv-download
            :data="apps"
            :columns="applicationIdColumns"
            :filters="applicationTableFilters"
            :default-sort="{
                prop: 'displayName',
                order: 'ascending'
            }">
            <template #headerActions>
                <search
                    v-model="applicationTableSearchValue"
                    class="application-table-search"
                    placeholder="Search" />
            </template>
        </pendo-table>
        <pendo-modal
            :visible="isConfirmDeleteModalVisible"
            class="delete-integration-key-modal"
            type="confirmation"
            title="Delete Integration Key?"
            message="Are you sure you want to delete this integration? Data will no longer be pulled to any sources depending on this integration key."
            @close="isConfirmDeleteModalVisible = false">
            <template #footer>
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    class="cancel-delete-key"
                    @click="isConfirmDeleteModalVisible = false" />
                <pendo-button
                    :loading="isDeletingKey"
                    theme="app"
                    type="danger"
                    label="Delete Key"
                    class="delete-integration-key delete-integration-key-confirm"
                    @click="onConfirmDeleteKey" />
            </template>
        </pendo-modal>
        <add-integration-key-modal
            v-if="isAddKeyModalVisible"
            @close="isAddKeyModalVisible = false"
            @createKey="generateKey" />
        <pendo-modal
            class="integration-key-warning-modal"
            title="New Integration Key"
            :show-close="true"
            :show-backdrop="true"
            :action-buttons="false"
            :height="250"
            :width="600"
            :visible="showKeyModal"
            @close="showKeyModal = false">
            <template #body>
                <div class="modal-body">
                    <code class="modal-body__key">{{ lastCreatedKey }}</code>
                    <br>
                    <pendo-alert
                        class="modal-body__disclaimer"
                        title=""
                        description="Make sure you save this key somewhere safe. You won't be able to view it after closing
                                this popup." />
                </div>
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
    PendoClipboard,
    PendoModal,
    PendoEmptyState,
    PendoActionsCell,
    PendoTable,
    PendoIcon,
    PendoButton,
    PendoCard,
    PendoAlert
} from '@pendo/components';
import Search from '@/components/Search';
import AddIntegrationKeyModal from './AddIntegrationKeyModal';
import { getPlatformLabel } from '@/stateless-components/utils/apps';

export default {
    name: 'Integrations',
    components: {
        PendoModal,
        PendoActionsCell,
        PendoTable,
        PendoIcon,
        PendoEmptyState,
        PendoButton,
        PendoCard,
        Search,
        AddIntegrationKeyModal,
        PendoAlert
    },
    directives: {
        PendoClipboard
    },
    data () {
        return {
            applicationTableSearchValue: '',
            currentlyEditingRow: {},
            columns: [
                {
                    prop: 'label',
                    label: 'Description',
                    sortable: true
                },
                {
                    prop: 'createdByUser.username',
                    label: 'Created By',
                    sortable: true
                },
                {
                    type: 'actions',
                    width: 60
                }
            ],
            applicationIdColumns: [
                {
                    prop: 'id',
                    label: 'Application ID',
                    sortable: true,
                    width: 200
                },
                {
                    prop: 'displayName',
                    label: 'Application Name',
                    sortable: true
                }
            ],
            isGeneratingKey: false,
            isDeletingKey: false,
            isConfirmDeleteModalVisible: false,
            isAddKeyModalVisible: false,
            pendingKeyToDelete: null,
            showKeyModal: false,
            lastCreatedKey: '',
            adoptMobileFlag: false
        };
    },
    computed: {
        ...mapState({
            isFetchingKeys: (state) => state.integrationKeys.isFetching
        }),
        ...mapGetters({
            allIntegrationKeys: 'integrationKeys/list',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeAppId: 'apps/activeId',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        applicationId () {
            return `Application ID: ${this.activeAppId}`;
        },
        applicationTableFilters () {
            return [
                {
                    prop: ['displayName', 'id'],
                    value: this.applicationTableSearchValue
                }
            ];
        },
        apps () {
            return this.listAllForActiveSubscription.map((app) => {
                return {
                    ...app,
                    platformLabel: getPlatformLabel(app)
                };
            });
        }
    },
    created () {
        this.loadAll();
        if (this.activeIsDigitalAdoption) {
            this.columns.splice(2, 0, {
                prop: 'readwrite',
                label: 'Allow Writes',
                sortable: false,
                width: '200px'
            });
        }
        this.adoptMobileFlag = this.hasSegmentFlag('adoptMobile');
        if (this.adoptMobileFlag) {
            this.applicationIdColumns = [
                ...this.applicationIdColumns,
                {
                    prop: 'platformLabel',
                    label: 'Platform',
                    sortable: true
                }
            ];
        }
    },
    methods: {
        ...mapActions({
            createKey: 'integrationKeys/create',
            updateKey: 'integrationKeys/update',
            deleteKey: 'integrationKeys/delete',
            loadAll: 'integrationKeys/loadAll'
        }),
        showConfirmDeleteModal (key) {
            this.pendingKeyToDelete = key;
            this.isConfirmDeleteModalVisible = true;
        },
        getRowIconType (row) {
            if (!row || !row.accessFlags) return 'x';

            return row.accessFlags.rw ? 'check' : 'x';
        },
        addIntegrationKey () {
            this.isAddKeyModalVisible = true;
        },
        async generateKey (createOptions = {}) {
            this.isGeneratingKey = true;
            try {
                await this.createKey({ createOptions });
                this.isAddKeyModalVisible = false;
                this.showKeyWarningModal(createOptions);
            } catch (err) {
                this.$emit('error', 'generating integration key');
            } finally {
                this.isGeneratingKey = false;
            }
        },
        showKeyWarningModal (createOptions) {
            this.showKeyModal = true;
            const matchingIntegrationKey = this.allIntegrationKeys.find((key) => key.label === createOptions.label);
            this.lastCreatedKey = matchingIntegrationKey.integrationkey;
        },
        async onConfirmDeleteKey () {
            this.isDeletingKey = true;
            try {
                await this.deleteKey({ key: this.pendingKeyToDelete });
                this.isConfirmDeleteModalVisible = false;
            } catch (error) {
                this.$emit('error', 'deleting integration key');
            } finally {
                this.isDeletingKey = false;
                this.pendingKeyToDelete = null;
            }
        },
        async onLabelChange (label) {
            const key = {
                ...this.currentlyEditingRow,
                label
            };

            try {
                await this.updateKey({ key });
            } catch (error) {
                this.$emit('error', 'changing key label');
            } finally {
                this.$refs.integrationKeyTable.updateRow(key);
                this.currentlyEditingRow = {};
            }
        }
    }
};
</script>

<style scoped lang="scss">
.integration-app-info {
    margin: 0px 0px 16px;
}

.app-id {
    color: $gray-lighter-2;
    line-height: 1.5em;

    &--label {
        font-weight: 700;
        color: $gray-primary;
        padding-bottom: 0.5em;
    }
}

.integration-key-table--empty {
    height: 240px;
}

.delete-integration-key-modal__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cancel-delete-key {
        margin-left: auto;
    }
}

.integration-key-table--copy {
    display: flex;
    align-items: center;

    .pendo-icon {
        cursor: pointer;
        margin-left: 8px;
    }
}

.integration-key-table--empty-actions {
    display: flex;
    justify-content: center;
}

.application-info-table {
    margin-top: 30px;
}

.modal-body {
    &__key {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 0 20px;
        color: #c7254e; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
        background-color: #f9f2f4;
        border-radius: 4px;
    }
}
</style>
